
import './App.css';
import Task from './Task';
import TaskForm from './TaskForm';
import { useState, useEffect } from 'react';

function App() {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if(tasks.length === 0) return;
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks])

  useEffect(() => {
    const tasks = JSON.parse(localStorage.getItem('tasks'))
    setTasks(tasks)
  }, [])

  function addTask(name) {
    setTasks(prev => {
      return [...prev, { name: name, done: false }];
    })
  }
  function removeTask(indexToRemove){
    setTasks(prev=>{
      return prev.filter((taskObject,index)=>index !== indexToRemove);
    })
  }

  function updateTaskDone(taskIndex, newDone){
    setTasks(prev=>{
      const newTasks = [...prev];
      newTasks[taskIndex].done = newDone;
      return newTasks   
    })
  }
  

  
  const numberCompelete = tasks.filter(t=>t.done).length
  const numberTotal = tasks.length;

  function getMessage(){
    const percentage = numberCompelete / numberTotal *100;
    if (percentage ===0){
      return 'Try to do at least one! 🙏 '
    }
    if (percentage === 100){
      return 'Yesss You do it 🤩'
    }
      return 'Keep it going 💪'
  }

  function renameTask(index,newName){
    setTasks(prev=>{
      const newTasks = [...prev];
      newTasks[index].name = newName
      return newTasks
    })
  }

  return (
    <main className="App">
      <h1>{numberCompelete}/{numberTotal} Complete</h1>
      <h2>{getMessage()}</h2>
      <TaskForm onAdd={addTask}></TaskForm>
      {tasks.map((task, index)=> (
        <Task {...task} 
        onRename={newName => renameTask(index,newName)}
        onTrash={()=>removeTask(index)}
        onToggle={done => updateTaskDone(index , done)} />
      ))}

    </main>
  );
}

export default App;
