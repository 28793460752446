import React from 'react'
import { useState } from 'react';
function TaskForm({onAdd}) {
    const[TaskName, setTaskName] = useState('');
    function handleSubmit(ev){
      ev.preventDefault();
      onAdd(TaskName);
      setTaskName('')
    }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <button>+</button>
        <input type="text" 
               value={TaskName} 
               onChange = {ev => setTaskName(ev.target.value)}
               placeholder='Your next task...'/>
    
      </form>
    </div>
  )
}

export default TaskForm
